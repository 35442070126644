import { Toaster } from '@i2e/components';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { SWRConfig } from 'swr';

import { LanguageProvider } from '@/context/language-provider';
import { TokenRefreshProvider } from '@/context/token-refresh-provider';
import { initSentry } from '@/lib/sentry';
import type { NextPageWithLayout } from 'types/page';

import '@/styles/globals.css';

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
};

const MyApp = ({ Component, pageProps }: AppPropsWithLayout) => {
    const router = useRouter();

    // Initialize Sentry
    initSentry();

    // Use the layout defined at the page level, if available
    const getLayout = Component.getLayout ?? ((page) => page);

    return (
        <LanguageProvider>
            <TokenRefreshProvider>
                <SWRConfig
                    value={{
                        onError: (error) => {
                            if (error.status === 401 && router.pathname !== '/welcome/login') {
                                window.location.href = '/goodbye';
                            }
                        },
                    }}
                >
                    <Head>
                        <title>In2Event</title>
                        <meta
                            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
                            name="viewport"
                        />
                    </Head>

                    {getLayout(<Component {...pageProps} />)}
                    <Toaster />
                </SWRConfig>
            </TokenRefreshProvider>
        </LanguageProvider>
    );
};

export default MyApp;
