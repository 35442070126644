import * as Sentry from '@sentry/nextjs';

export const initSentry = (): void => {
    if (process.env.NODE_ENV === 'production') {
        Sentry.init({
            dsn: 'https://a68f8e033f3c489492cf8b32caf9c5a6@o167748.ingest.sentry.io/4504360535457792',
            tracesSampleRate: 0,
        });
    }
};

export { Sentry };
